import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: '/web', pathMatch: 'full' },
  {
    path: 'web',
    loadChildren: () => import('./web/web/web.module').then(m => m.WebModule)  // Lazy loading of WebModule
  },
  { path: '**', redirectTo: '/web' }  // Wildcard route for 404
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
